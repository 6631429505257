.play_screen {
  background-size: calc(320px / 10);
  background-repeat: repeat;
}

.dark {
  background-image: url('https://w-tetris.vercel.app/images/dark-green/bg.png');
}

.light {
  background-image: url('https://w-tetris.vercel.app/images/light-green/bg.png');
}

@media (max-width: 816px) {
  .play_screen {
    background-size: calc(252px / 10);
    width: 256px;
  }
}

@media (max-width: 414px) {
  .play_screen {
    background-size: calc(212px / 10);
    width: 216px;
  }
}
